/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState, useCallback, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import AppContext from '../../context';
import logger from '../../utils/logger';
import {
  getLocalStorageTablePref, updateTablePreferenceLocalStorage, diffListOfObjects, tableColumnPreferenceLoader,
} from '../../utils/index';
import APIWrapper from '../../utils/graphqlwrapper';
import { importHistoryColumns } from '../../utils/schemaInfo';

import Table from '../Table';
import AdvancedSearch from '../AdvancedSearch';
import { createImportHistoryRows } from '../../utils/tableHelpers';

const initColumnVisibilityModel = { createdAt: false, updatedAt: false };

const initPageState = {
  isLoading: true,
  page: 0, // mui pages are 0 indexed
  pageSize: 50,
  data: [],
  total: 0,
};

const defaultRowSort = [{ field: 'updatedAt', sort: 'desc' }];

export default function ImportHistory(props) {
  const { client } = props;

  const { setShouldLogOut, authenticated } = useContext(AppContext);
  const [graphqlImportHistoryNextToken, setGraphqlImportHistoryNextToken] = useState('');
  const [importHistoryPageState, setImportHistoryPageState] = useState(initPageState);
  const [shouldRefetchBasicImportHistory, setShouldRefetchBasicImportHistory] = useState(false);
  const hasFetchedImportHistoryData = useRef(false);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(initColumnVisibilityModel);
  const [importHistorySortModel, setImportHistorySortModel] = React.useState(defaultRowSort);
  const sortModel = { sortModel: [{ ...importHistorySortModel, sort: 'desc' }] }; // not sure about sort key added here
  const [importHistoryFilterModel, setImportHistoryFilterModel] = React.useState({ items: [] });
  const [orderedColumns, setOrderedColumns] = useState(importHistoryColumns.slice(0));
  // handle basic <-> advance search swap
  const [isAdvanced, setIsAdvanced] = useState(false);

  const getDefaultColumns = () => importHistoryColumns;

  useEffect(() => {
    document.title = 'MissionPortal | Transactions';
  }, []);

  function fetchBasicImportHistory() {
    document.title = 'MissionPortal | Transaction exclusions upload and history';

    const fetchImportHistory = async (token = graphqlImportHistoryNextToken) => {
      const history = await APIWrapper.queryApi(
        { query: 'importHistoryByClient' },
        setShouldLogOut,
        {
          client,
          limit: 2000,
          nextToken: token ? graphqlImportHistoryNextToken : null,
          sortDirection: 'DESC',
        },
      )
        .then((resp) => resp)
        .catch((err) => logger.error(`API error fetching importHistoryByClient for ${client}: ${err}`));

      const formedRows = createImportHistoryRows(history.data);
      setGraphqlImportHistoryNextToken(history.nextToken);
      setImportHistoryPageState((old) => ({
        ...old, isLoading: false, data: formedRows, total: formedRows.length,
      }));
    };

    if (!hasFetchedImportHistoryData.current && authenticated) {
      fetchImportHistory()
        .then(() => {
          hasFetchedImportHistoryData.current = true;
        })
        .catch((e) => {
          logger.error('Error fetching import history: ', e);
        });
    }
  }

  const customSetOrdered = (neworder) => {
    updateTablePreferenceLocalStorage('importhistory', 'column', neworder);
  };

  useEffect(() => {
    fetchBasicImportHistory();
  }, []);

  useEffect(() => {
    if (shouldRefetchBasicImportHistory) {
      fetchBasicImportHistory();
      setShouldRefetchBasicImportHistory(false);
    }
  }, [shouldRefetchBasicImportHistory]);

  useEffect(() => {
    const importHistoryColumnVisibility = getLocalStorageTablePref('importhistory', 'column-visibility');
    if (!importHistoryColumnVisibility) {
      updateTablePreferenceLocalStorage('importhistory', 'column-visibility', initColumnVisibilityModel);
    } else {
      setColumnVisibilityModel(importHistoryColumnVisibility);
    }
  }, []);

  useEffect(() => {
    const importHistoryRowSort = getLocalStorageTablePref('importhistory', 'sort');
    if (!importHistoryRowSort) {
      updateTablePreferenceLocalStorage('importhistory', 'sort', defaultRowSort);
    } else {
      setImportHistorySortModel(importHistoryRowSort);
    }
  }, []);

  useEffect(() => {
    tableColumnPreferenceLoader('importhistory', 'column', importHistoryColumns, setOrderedColumns);
  }, [importHistoryPageState, importHistoryColumns]);

  useEffect(() => {
    const importHistoryFilters = getLocalStorageTablePref('importhistory', 'filter');
    if (importHistoryFilters) {
      setImportHistoryFilterModel(importHistoryFilters);
    } else {
      updateTablePreferenceLocalStorage('importhistory', 'filter', { items: [] });
    }
  }, []);

  const setAdvancedSearch = () => {
    setIsAdvanced(true);
  };

  const setBasicSearch = () => {
    setIsAdvanced(false);
    // setShouldRefetchBasicSources(true);
  };

  return (
    <>
      {isAdvanced && (
      <>
        <div className="header_table_button_container">
          <Button
            variant="outlined"
            onClick={setBasicSearch}
            className="toggle-search-type"
          >
            cancel
          </Button>
        </div>

        <AdvancedSearch
          columns={orderedColumns}
          setOrderedColumns={setOrderedColumns}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          pageState={importHistoryPageState}
          updatePageState={setImportHistoryPageState}
          // don't think this is getting used across any advancedSearch components and it is to fix in a future ticket
          sortModel={sortModel}
          defaultFilter={[{ client_id: { eq: client } }]}
          excludeFields={['client']}
          queryName="importHistoryByClient"
          createRows={createImportHistoryRows}
          tablePrefix="importhistory-adv"
          getDefaultColumns={getDefaultColumns}
        />
      </>
      )}
      {!isAdvanced && (
        <>
          {/* hiding the button bc Advanced Search would not be used but including
           it as way to get the advanced search table */}
          {/* <div className="header_table_button_container">
            <Button
              variant="outlined"
              onClick={setAdvancedSearch}
              className="toggle-search-type"
            >
              Global Search
            </Button>
          </div> */}

          <Table
            columns={orderedColumns}
            setOrderedColumns={setOrderedColumns}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            updatePageState={setImportHistoryPageState}
            pageState={importHistoryPageState}
            sortModel={importHistorySortModel}
            setSortModel={setImportHistorySortModel}
            tablePrefix="importhistory"
            filterModel={importHistoryFilterModel}
            setFilterModel={setImportHistoryFilterModel}
            getDefaultColumns={getDefaultColumns}
            customSetOrdered={customSetOrdered}
            customRowHeight="auto"
          />
        </>
      )}
    </>
  );
}

ImportHistory.propTypes = {
  client: PropTypes.string,
};
