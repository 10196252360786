/* eslint-disable consistent-return */
import {
  CreateLogStreamCommand, CloudWatchLogsClient, DescribeLogStreamsCommand, PutLogEventsCommand,
} from '@aws-sdk/client-cloudwatch-logs';

const createLogEvent = (message) => ({
  message: JSON.stringify(message),
  timestamp: Date.now(),
});

const cloudwatch = async (logEvent, message) => {
  /**
    * Add logs and send to Cloudwatch with necessary formatting using AWS SDK
    * Will break logs down by date and by hour to make it easier to look through if necessary
    * Will only write to cloudwatch for staging and production environment
    * Docs: https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-client-cloudwatch-logs/
    * @param {Array} logEvent object constructed for Cloudwatch in createLogEvent()
    * @param {String} message string to log
    * @returns {Console} will use console.log if using on dev environment
  */
  let logger;
  try {
    if (process.env.REACT_APP_ENVIRON !== 'production'
            && process.env.REACT_APP_ENVIRON !== 'staging') {
      return console.log(message);
    }

    if (process.env.REACT_APP_ENVIRON === 'production') {
      logger = 'missionportal-logging';
    } else if (process.env.REACT_APP_ENVIRON === 'staging') {
      logger = 'missionportal-staging-logging';
    }

    // format hour to eastern time so log streams in the same hour are not affected by timezone
    const now = new Date();
    const dateString = now.toISOString().split('T')[0];
    const options = {
      timeZone: 'America/New_York',
      hour: '2-digit',
      hour12: false,
    };
    const easternHour = new Intl.DateTimeFormat('en-US', options).format(now);

    const client = new CloudWatchLogsClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      },
    });

    // check if stream name exists already for log group
    const checkStream = new DescribeLogStreamsCommand({
      logGroupName: logger,
      logStreamNamePrefix: `${logger}-${dateString}_${easternHour}`,
    });
    const checkStreamReq = await client.send(checkStream);
    if (Array.isArray(checkStreamReq.logStreams) && !checkStreamReq.logStreams.length) {
      // stream name doesn't exist. let's create it.
      const createStream = new CreateLogStreamCommand({
        logGroupName: logger,
        logStreamName: `${logger}-${dateString}_${easternHour}`,
      });
      await client.send(createStream);
    }

    // send to CloudWatch
    const command = new PutLogEventsCommand({
      logGroupName: logger,
      logStreamName: `${logger}-${dateString}_${easternHour}`,
      logEvents: [logEvent],
    });

    await client.send(command);
  } catch (err) {
    console.error(`Error with logs: ${err}`);
  }
};

const logger = {
  info: (message) => cloudwatch(createLogEvent(message), message),
  debug: (message) => cloudwatch(createLogEvent(message), message),
  error: (message) => cloudwatch(createLogEvent(message), message),
};

export default logger;
