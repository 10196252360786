import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import logger from '../utils/logger';
import TabPanel from '../components/TabPanel';
import { logReactErrBoundaryError } from '../utils';
import FallbackOnError from '../components/FallbackOnError';
import Transactions from '../components/client/AllTransactions';
import TransactionsExclusions from './TransactionsExclusions';

function TransactionsContainer(props) {
  const { userEmail } = props;
  const [tab, setTab] = useState(0);
  const param = useParams();
  const { client } = param;

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <header className="App-header">
          <h2>Transactions</h2>
        </header>
        <Box sx={{ width: '100%' }}>
          <>
            {' '}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Import exclusion file" />
                <Tab label="View all transactions" />
              </Tabs>
            </Box>
            <>
              <TabPanel label="Import exclusion file" value={tab} index={0}>
                <TransactionsExclusions client={client} />
              </TabPanel>
              <TabPanel label="View all transactions" value={tab} index={1}>
                <Transactions userEmail={userEmail} />
              </TabPanel>
            </>
          </>
        </Box>
      </div>
    </ErrorBoundary>
  );
}

export default TransactionsContainer;

TransactionsContainer.propTypes = {
  userEmail: PropTypes.string,
};
