/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  ThemeProvider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  North, Search, South,
} from '@mui/icons-material';
import logger from '../utils/logger';
import { logReactErrBoundaryError } from '../utils';
import theme from './themes/Theme';

import FallbackOnError from './FallbackOnError';
import SearchCriteria from './SearchCriteria';
import { formatChip } from './AdvancedSearchChip';

/**
 * this looks absurd, but the goal here is to format the search criteria
 * into the desired format for our api
 * or or statements they need to be in a list:
 *  or: [
 *    {code: {contains: "hello"}},
 *    {code: {contains: "world"}}
 *  ]
 * ands should be formatted like this:
 *  and: {
 *    client_id: {eq: "Simon"},
 * }
 * when chaining together multiple ands/ors they must be nested
 */
/**
 * Formats the search criteria into a desired format for the API
 * @param {Array} search - user-defined search criteria
 * @param {Array} lockedCriteria - default/locked criteria that cannot be modified by the user
 * @returns {Object} - formatted search criteria that's usable by our API
 */
const formatSearch = (search, lockedCriteria = []) => {
  // combine user-defined search criteria with locked criteria
  const allCriteria = search.concat(lockedCriteria);

  // init empty obj to store formatted search criteria
  let formattedSearchCriteria = {};

  // helper function to add to formatted search criteria - basically this handles the
  // nesting of the new criteria in the formatted search criteria
  const addToFormattedSearch = (criteria, condition) => {
    // if it's an 'and' operation, we want to nest the criteria inside w/ the key and
    if (condition === 'and') {
      // if there's nothing in the formatted search criteria, just set it to the criteria
      if (Object.keys(formattedSearchCriteria).length === 0) {
        formattedSearchCriteria = criteria;
      } else {
        // if there's something in the formatted search criteria, nest it inside
        const newFormat = criteria;
        newFormat.and = formattedSearchCriteria;
        formattedSearchCriteria = newFormat;
      }
    } else if (condition === 'or') {
      // if it's an 'or' operation, we want to add these all to a list
      if (Object.keys(formattedSearchCriteria).length === 0) {
        // if there's nothing in the formatted search criteria, just set it to the criteria
        formattedSearchCriteria.or = criteria;
      } else {
        // if there's something in the formatted search criteria, nest it inside
        const newFormat = criteria;
        newFormat.or = formattedSearchCriteria.or;
        formattedSearchCriteria = newFormat;
      }
    }
  };

  const formatWildCard = (criteria) => {
    const wordList = criteria.split(/[^a-zA-Z0-9]+/).filter(Boolean); // split on non-alphanumeric characters, remove empty strings
    const searchString = wordList.join('*');
    return `*${searchString}*`;
  };

  allCriteria.forEach((criteria) => {
    // if there's a children array, we need to check and see if there's children
    // these are or operations and we need to format these differently
    if (criteria.children) {
      if (criteria.children.length > 0) {
        // all children are ors and must be in a list before being added to the formatted searchs
        const orList = criteria.children.map((child) => {
          // there's no such thing as between for the api, between is just a
          // gte and lte combined to make it easier for the user
          if (child.op === 'between') {
            return {
              [criteria.col]: {
                gte: child.criteria[0],
                lte: child.criteria[1],
              },
            };
          } if (child.op === 'customsearch') {
            const searchString = formatWildCard(child.criteria);
            return {
              or: [
                {
                  [criteria.col]: {
                    wildcard: searchString,
                  },
                }, {
                  [criteria.col]: {
                    match: child.criteria,
                  },
                },
              ],
            };
          }
          // if it's not between, just format it normally - col: {op: criteria}
          return {
            [criteria.col]: {
              [child.op]: child.criteria,
            },
          };
        });
        if (criteria.op === 'between') {
          // this is for the parent if it's a between - we also need to turn it into gte and lte
          orList.push({
            [criteria.col]: {
              gte: criteria.criteria[0],
              lte: criteria.criteria[1],
            },
          });
        } else if (criteria.op === 'customsearch') {
          const searchString = formatWildCard(criteria.criteria);
          orList.push({
            or: [
              {
                [criteria.col]: {
                  wildcard: searchString,
                },
              }, {
                [criteria.col]: {
                  match: criteria.criteria,
                },
              },
            ],
          });
        } else {
          // if it's not between, just format it normally - col: {op: criteria}
          orList.push({
            [criteria.col]: {
              [criteria.op]: criteria.criteria,
            },
          });
        }
        // use helper function to add it properly to the existing criteria
        addToFormattedSearch(orList, 'or');
      } else {
        // if the criteria has no children, it must be an 'and' condition
        let andCriteria = {};
        if (criteria.op === 'between') {
          // turn between into gte and lte ops for the API
          andCriteria = {
            [criteria.col]: {
              gte: criteria.criteria[0],
              lte: criteria.criteria[1],
            },
          };
        } else if (criteria.op === 'customsearch') {
          const searchString = formatWildCard(criteria.criteria);
          andCriteria = {
            or: [
              {
                [criteria.col]: {
                  wildcard: searchString,
                },
              }, {
                [criteria.col]: {
                  match: criteria.criteria,
                },
              },
            ],
          };
        } else {
          andCriteria = {
            [criteria.col]: {
              [criteria.op]: criteria.criteria,
            },
          };
        }
        // use helper function to add it properly to the existing criteria
        addToFormattedSearch(andCriteria, 'and');
      }
    } else {
      // if there's no children, it must be an 'and' condition
      let andCriteria = {};
      if (criteria.op === 'between') {
        andCriteria = {
          [criteria.col]: {
            gte: criteria.criteria[0],
            lte: criteria.criteria[1],
          },
        };
      } else if (criteria.op === 'customsearch') {
        const searchString = formatWildCard(criteria.criteria);
        andCriteria = {
          or: [
            {
              [criteria.col]: {
                wildcard: searchString,
              },
            }, {
              [criteria.col]: {
                match: criteria.criteria,
              },
            },
          ],
        };
      } else {
        andCriteria = {
          [criteria.col]: {
            [criteria.op]: criteria.criteria,
          },
        };
      }
      // use helper function to add it properly to the existing criteria
      addToFormattedSearch(andCriteria, 'and');
    }
  });
  // return the formatted search criteria the API can use
  return formattedSearchCriteria;
};

/**
 * Component for selecting sorting options
 * @param {Object} currSort - current sort config (field, direction)
 * @param {function} setCurrSort - function to set sort config (field, direction)
 * @param {Array} columns - list of columns with their properties
 */
function SortSelector({
  currSort, setCurrSort, columns,
}) {
  const [sortBy, setSortBy] = useState(currSort.field);
  const [sortDir, setSortDir] = useState(currSort.direction);

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setCurrSort({
      field: event.target.value,
      direction: sortDir,
    });
  };

  const handleSortDirChange = (event) => {
    setSortDir(event.target.value);
    setCurrSort({
      field: sortBy,
      direction: event.target.value,
    });
  };

  const colsByField = columns.reduce((acc, curr) => { acc[curr.field] = curr; return acc; }, {});

  // specify fields that are not sortable using appsync
  const unsortableFields = ['channel', 'channel_category', 'source', 'most_recent_spend', 'transactions', 'acquisitions'];

  // filter out columns that are not sortable in appsync
  const sortableCol = columns.filter((column) => !unsortableFields.includes(column.field));

  return (
    <div id="sort-container">
      <h3>Sort By</h3>
      <div id="sort-selector">
        <Select id="sort-column" value={sortBy} onChange={handleSortChange}>
          {sortableCol.map((column) => (
            <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
          ))}
        </Select>
        <Select id="sort-direction" value={sortDir} onChange={handleSortDirChange}>
          <MenuItem value="asc">
            {' '}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <North />
              {colsByField[sortBy].format === 'String' ? <div>A-Z</div> : <div>Ascending</div>}
            </div>
          </MenuItem>
          <MenuItem value="desc">
            {' '}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <South />
              {colsByField[sortBy].format === 'String' ? <div>Z-A</div> : <div>Descending</div>}
            </div>
          </MenuItem>
        </Select>
      </div>

    </div>
  );
}

SortSelector.propTypes = {
  currSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  setCurrSort: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
};

/**
 * Main component for advanced search dialog
 * @param {Array} currUserSearch - current user-defined search criteria
 * @param {function} setCurrUserSearch - function to set the user-defined search criteria
 * @param {function} searchSetter - function to handle search (locked + user) setting
 * @param {Array} lockedCriteria - default/locked criteria cannot be changed by the user
 * @param {Array} columns - list of columns with their properties
 * @param {Array} lockedColumns - list of cols user can't modify/add additional criteria for
 * @param {function} sortSetter - function to set sorting config
 * @param {Object} defaultSort - default sorting config
 * @param {function} setUserChips - function to set user-defined search chips
 * @param {boolean} initOpenState - initial state of the search dialog (true = open, false = closed)
 * @param {function} setInitSearchExecuted - function to set the initial search executed state,
 * for some components (like transactions) we don't want to show the user the table until
 * they execute a search
 */
function AdvancedSearchV2({
  currUserSearch,
  setCurrUserSearch,
  searchSetter,
  lockedCriteria = [],
  defaultCriteria = [],
  columns,
  lockedColumns,
  sortSetter,
  defaultSort,
  setUserChips,
  initOpenState = false,
  setInitSearchExecuted = () => {},
}) {
  const [open, setOpen] = React.useState(initOpenState);
  const blankSearch = {
    col: '',
    op: '',
    criteria: '',
    showOptions: false,
    children: [],
  };

  // eslint-disable-next-line max-len
  const selectableCols = columns.filter((column) => !column.excludeFromSearch && !lockedColumns.includes(column.field));

  let formattedLockedCriteria = [];
  if (lockedCriteria.length > 0) {
    formattedLockedCriteria = lockedCriteria.map((criteria) => ({
      col: criteria.col,
      op: criteria.op,
      criteria: criteria.criteria,
      showOptions: false,
    }));
  }

  let initCurrAllSearch = defaultCriteria.map((criteria, index) => ({
    ...criteria,
    id: index + formattedLockedCriteria.length,
    children: criteria.children || [],
  }));

  // searchCriteriaIdx represents the index for the next search criteria entry
  // it's initialized to formattedLockedCriteria.length + initCurrAllSearch.length to ensure
  // our ids don't overlap
  // eslint-disable-next-line max-len
  const [searchCriteriaIdx, setSearchCriteriaIdx] = useState(formattedLockedCriteria.length + initCurrAllSearch.length);

  initCurrAllSearch = initCurrAllSearch.concat([{ ...blankSearch, id: searchCriteriaIdx }]);
  const [currAllSearch, setCurrAllSearch] = useState(initCurrAllSearch);

  const [currSort, setCurrSort] = useState(defaultSort);

  const handleSubmit = () => {
    const formattedSearch = formatSearch(currAllSearch, lockedCriteria);
    const userSearch = formatChip(columns, currAllSearch);
    setCurrUserSearch(currAllSearch);
    searchSetter(formattedSearch);
    setUserChips(userSearch);
    sortSetter(currSort);
    setOpen(false);
    setInitSearchExecuted(true);
  };

  useEffect(() => {
    if (currUserSearch) {
      setCurrAllSearch(currUserSearch);
      const formattedSearch = formatSearch(currUserSearch, lockedCriteria);
      searchSetter(formattedSearch);
      setUserChips(formatChip(columns, currUserSearch));
    }
  }, [currUserSearch]);

  const handleAdd = () => {
    const newSearch = blankSearch;
    newSearch.id = searchCriteriaIdx + 1;
    setCurrAllSearch([
      ...currAllSearch,
      newSearch,
    ]);
    setSearchCriteriaIdx(searchCriteriaIdx + 1);
  };

  const addChild = (parentIndex) => {
    const parent = currAllSearch[parentIndex];
    parent.children.push({
      id: searchCriteriaIdx + 1,
      col: parent.col,
      op: '',
      criteria: '',
    });
    setSearchCriteriaIdx(searchCriteriaIdx + 1);
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <Button variant="contained" onClick={() => setOpen(true)}>
        <Search />
        Search
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xl"
        id="advanced-search-dialog"
      >
        <DialogTitle>Advanced Search</DialogTitle>
        <div id="advanced-search-dialog-body">
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (_theme) => _theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="right-button">
            <Button
              variant="contained"
              onClick={() => {
                setSearchCriteriaIdx(searchCriteriaIdx + 1);
                const newSearch = blankSearch;
                newSearch.id = searchCriteriaIdx + 1;
                setCurrAllSearch([]);
              }}
              sx={{
                bgcolor: 'error.main',
                ':hover': {
                  bgcolor: 'error.mainHover',
                },
              }}
              className="right-button"
            >
              Clear All
            </Button>
          </div>
          <div id="advanced-search-options">
            <div className="search-half">
              <h3>Search Criteria</h3>
              {lockedCriteria.map((criteria, index) => (
                <div key={index}>
                  <SearchCriteria
                    allCols={columns}
                    currIndex={index}
                    setSearch={() => {}}
                    key={index}
                    isOr={false}
                    id={index}
                    disabled
                    currValues={criteria}
                    fixedValues={criteria}
                  />
                </div>
              ))}
              {currAllSearch.map((searchEntry, parentCurrIndex) => (
                <div key={searchEntry.id}>
                  <SearchCriteria
                    allCols={selectableCols}
                    currIndex={parentCurrIndex}
                    setSearch={setCurrAllSearch}
                    isOr={false}
                    id={searchEntry.id}
                    currValues={searchEntry}
                  />
                  {searchEntry.children.map((child, currChildIndex) => (
                    <div
                      key={child.id}
                    >
                      <SearchCriteria
                        allCols={columns}
                        fixedCol={searchEntry.col}
                        parentIndex={parentCurrIndex}
                        currIndex={currChildIndex}
                        setSearch={setCurrAllSearch}
                        key={currChildIndex}
                        isOr
                        id={child.id}
                        currValues={child}
                      />
                    </div>
                  ))}
                  <div className="or-button-container">
                    <Button
                      variant="outlined"
                      onClick={() => addChild(parentCurrIndex, 'or')}
                      size="small"
                    >
                      + OR
                    </Button>
                  </div>
                </div>
              ))}
              <ThemeProvider theme={theme}>
                <div className="right-button">
                  <Button
                    variant="contained"
                    onClick={handleAdd}
                    startIcon={<AddIcon />}
                    color="tertiary"
                  >
                    Add
                  </Button>
                </div>
              </ThemeProvider>
            </div>
            <div className="search-half">
              <div id="sort-selector">
                <SortSelector
                  currSort={currSort}
                  setCurrSort={setCurrSort}
                  columns={selectableCols}
                />
              </div>
            </div>
          </div>
          <div className="right-button submit-button">
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              size="large"
              sx={{ width: 200 }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Dialog>
    </ErrorBoundary>
  );
}

// currently not using it elsewhere - just exporting it for testing purposes
export { formatSearch };

export default AdvancedSearchV2;

AdvancedSearchV2.propTypes = {
  currUserSearch: PropTypes.oneOfType([PropTypes.array]),
  setCurrUserSearch: PropTypes.func.isRequired,
  searchSetter: PropTypes.func.isRequired,
  lockedCriteria: PropTypes.arrayOf(PropTypes.shape({
    col: PropTypes.string.isRequired,
    op: PropTypes.string.isRequired,
    criteria: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      col: PropTypes.string.isRequired,
      op: PropTypes.string.isRequired,
      criteria: PropTypes.string.isRequired,
    })),
  })),
  defaultCriteria: PropTypes.arrayOf(PropTypes.shape({
    col: PropTypes.string.isRequired,
    op: PropTypes.string.isRequired,
    criteria: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      col: PropTypes.string.isRequired,
      op: PropTypes.string.isRequired,
      criteria: PropTypes.string.isRequired,
    })),
  })),
  defaultSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  lockedColumns: PropTypes.arrayOf(PropTypes.string),
  sortSetter: PropTypes.func.isRequired,
  setUserChips: PropTypes.func,
  initOpenState: PropTypes.bool,
  setInitSearchExecuted: PropTypes.func,
};
