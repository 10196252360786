import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Box,
  Tabs,
  Tab,
} from '@mui/material';

import FallbackOnError from '../components/FallbackOnError';
import TabPanel from '../components/TabPanel';
import HouseFile from '../components/client/HouseFile';
import NewToList from '../components/client/NewToList';
import logger from '../utils/logger';
import { logReactErrBoundaryError } from '../utils';

function TdcProducts() {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    document.title = 'MissionPortal | TDC Products';
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <header>
        <h2>The Digital Co-Op Products</h2>
      </header>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="New to list" />
          <Tab label="House file" />
        </Tabs>
      </Box>
      <Box>
        <h3>Overview</h3>
        <p>
          The Digital Co-op&apos;s core offerings include our fixed cost list growth solutions.
          Our innovative technology leverages predictive analytics and a vast network of shared
          data among co-op partners to pinpoint high-potential new donors,
          modeled for an organization. Since June 2020, our solutions
          have already helped partners raise over $58 million,
          proving our ability to deliver exceptional returns and
          scale rapidly while maintaining list health.
        </p>
      </Box>
      <>
        <TabPanel label="New to list" value={tab} index={0}>
          <NewToList />
        </TabPanel>
        <TabPanel label="House file" value={tab} index={1}>
          <HouseFile />
        </TabPanel>
      </>
    </ErrorBoundary>
  );
}

export default TdcProducts;
