import React, { useEffect, useState, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Amplify, Auth } from 'aws-amplify';
import { ThemeProvider } from '@mui/material/styles';
import jwtDecode from 'jwt-decode';
import APIWrapper from './utils/graphqlwrapper';
import AppContext from './context';
import { authconfig, awsconfig } from './aws-config';

import ProtectedRoute from './utils/ProtectedRoute';
import logger from './utils/logger';

// components
import Menu from './components/Menu';
import Loading from './components/Loading';

// views
import Profile from './views/Profile';
import ClientLandingPage from './views/ClientLanding';
import ClientList from './views/ClientList';
import ClientInfo from './views/ClientInfo';
import Sources from './components/client/Sources';
import Codes from './components/client/Codes';
import TDCAcquisitionsLanding from './views/reporting/TDCAcquisitionsLanding';
import TDCReactivationLanding from './views/reporting/TDCReactivationLanding';
import TDCStrategyLanding from './views/reporting/TDCStrategyLanding';
import SSRMultiChannelLanding from './views/reporting/SSRMultiChannelLanding';
import SSREmailLanding from './views/reporting/SSREmailLanding';
import TransactionsContainer from './views/TransactionsContainer';
import SSRAdsLanding from './views/reporting/SSRAdsLanding';
import RunAdsReport from './components/client/AdsResultsReport';
import TdcProducts from './views/TdcProducts';
import SettingsPage from './views/Settings';
import CallbackPage from './views/Callback';
import NotFound from './views/NotFound';
import Unauthorized from './views/401';
import theme from './components/themes/Theme';
import {
  COOP_ACQ_INVENTORY,
  COOP_ACQ_DELIV_CHECKS,
  COOP_ACQ_DELIV_METRICS,
  COOP_ACQ_DELIV_PERFORMANCE_ANALYSIS,
  COOP_REAC_INVENTORY,
  COOP_REAC_PERFORMANCE,
  COOP_STRAT_CLIENT_REV,
  COOP_STRAT_COOP_DASH,
  COOP_STRAT_MARKETING_TRENDS,
  COOP_STRAT_FIXED_COST_LANDSCAPE,
  SUM_BY_SOURCE_PARAMS,
  SSR_DELIVERABILITY_DASH,
  SSR_CHAMPY_ADS,
} from './utils/reportingConstants';

// auth/protect
import Login from './utils/Login';
import Logout from './utils/Logout';

// styles
import './App.scss';
import AdvancedSearch from './components/AdvancedSearch';
import Insights from './views/Insights';
import ViewReports from './views/reporting/ViewReports';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App() {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();
  const [shouldLogOut, setShouldLogOut] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [auth0Tok, setAuth0Tok] = useState('');
  // useMemo for context will prevent unnecessary rerenders when user and authenticated
  // values have not changed. All future context values should be added here.
  const contextValues = useMemo(
    () => ({
      user,
      authenticated,
      setAuthenticated,
      shouldLogOut,
      setShouldLogOut,
      auth0Tok,
      setAuth0Tok,
    }),
    [user, authenticated, shouldLogOut, auth0Tok],
  );
  const [token, setToken] = useState(null);

  const TDCAdmins = [
    'ambika@missionwired.com',
    'mary.sulaiman@missionwired.com',
    'brook.denny@missionwired.com',
    'todd@missionwired.com',
    'nathan.chong@missionwired.com',
    'ryan.rasmussen@missionwired.com',
    'alisha.edington@missionwired.com',
    'avery.blair@missionwired.com',
    'caitlin.mcmahon@missionwired.com',
    'jadiva.montealegre@missionwired.com',
    'robiul.hassan@missionwired.com',
    'isidro.camacho@missionwired.com',
    'edgar.reyes@missionwired.com',
    'harry.echtman@missionwired.com',
  ];

  const dataEngineers = [
    'kelsey.evans@missionwired.com',
    'peter.mason@missionwired.com',
    'kaylin.dee@missionwired.com',
    'edgar.reyes@missionwired.com',
    'alexis.tatore@missionwired.com',
    'julie.parsons@missionwired.com',
  ];

  let hasTDCAdminPermission = false;
  let hasDataEngineerPermission = false;
  if (user) {
    hasTDCAdminPermission = TDCAdmins.indexOf(user.email) > -1;
    hasDataEngineerPermission = dataEngineers.indexOf(user.email) > -1;
  }

  useEffect(() => {
    async function doFedSignIn(accessToken) {
      // get expiration timestamp from token
      const { exp } = jwtDecode(accessToken);

      // check if there's an existing authenticated user
      const currentUser = await Auth.currentAuthenticatedUser().catch(() => null);

      // doing a federated login in twice can cause issues,
      // so let's make sure they're logged out
      if (currentUser) {
        await Auth.signOut();
      }

      await Auth.federatedSignIn(
        authconfig.domain, // The Auth0 Domain,
        {
          token: accessToken, // The access token from Auth0
          expires_at: exp * 1000, // the expiration timestamp
        },
        user,
      )
        .then(() => {
          Auth.currentAuthenticatedUser();
        })
        .then(() => {
          Auth.currentUserCredentials();
          setUserEmail(user.email);
        })
        .then(() => {
          setAuthenticated(true);
        })
        .catch((e) => {
          logger.error('Federated sign in failed.', e);
        });
    }

    const getAuth0Token = async () => {
      let accessToken;
      if (isAuthenticated) {
        try {
          accessToken = await getAccessTokenSilently();
          if (!isLoading && accessToken) {
            await doFedSignIn(accessToken);
          }
          // set auth0Tok used in app context
          setAuth0Tok(accessToken);
        } catch (error) {
          logger.error(`Error getting access token from Auth0: ${error}`);
          // user is not authenticated or there was an issue with the refresh token, so log them out
          setShouldLogOut(true);
        }
      }
      return accessToken;
    };

    getAuth0Token();
  }, [isAuthenticated, user]);

  useEffect(() => {
    const isMenuOpen = localStorage.getItem('menu-open');
    if (isMenuOpen === null) {
      localStorage.setItem('menu-open', true);
    }
  }, []);

  useEffect(() => {
    async function getToken() {
      const payload = {
        userEmail,
      };

      const input = JSON.stringify(payload);

      let tok;
      await APIWrapper.queryApi(
        { query: 'getTokenTC' },
        setShouldLogOut,
        { input },
      ).then((resp) => {
        tok = JSON.parse(resp.data);
        setToken(tok.body);
      }).catch((e) => logger.error(`API Error fetching token from getTokenTC lambda: ${e}`));
    }

    if (authenticated) {
      getToken();
    }
  }, [authenticated]);

  if (isLoading) {
    return <Loading />;
  }
  if (shouldLogOut) {
    return (
      <AppContext.Provider value={contextValues}>
        <Logout />
      </AppContext.Provider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={contextValues}>
        <Routes>
          {['/', '/dashboard', '/clientlist', '/home'].map((path) => (
            <Route
              key="homepage" // doesn't unmount components
              path={path}
              element={(
                <ProtectedRoute
                  component={Menu}
                  body={(
                    <ClientList
                      hasTDCAdminPermission={hasTDCAdminPermission}
                      hasDataEngineerPermission={hasDataEngineerPermission}
                    />
                  )}
                />
              )}
            />
          ))}
          <Route
            path="*"
            element={<ProtectedRoute component={Menu} body={<NotFound />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute component={Menu} body={<Profile />} />}
          />
          <Route
            path="/client/:client/"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={
                  (
                    <ClientLandingPage
                      hasTDCAdminPermission={hasTDCAdminPermission}
                      hasDataEngineerPermission={hasDataEngineerPermission}
                    />
                  )
                }
              />
            )}
          />
          <Route
            path="/client/:client/settings"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={
                  (
                    <ClientInfo
                      hasTDCAdminPermission={hasTDCAdminPermission}
                      hasDataEngineerPermission={hasDataEngineerPermission}
                      userEmail={userEmail}
                    />
                  )
                }
              />
            )}
          />
          <Route
            path="/client/:client/sources"
            exact
            element={
              <ProtectedRoute component={Menu} body={<Sources />} />
            }
          />
          <Route
            path="/client/:client/codes"
            exact
            element={
              <ProtectedRoute component={Menu} body={<Codes />} />
            }
          />
          <Route
            path="/client/:client/transactions"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <TransactionsContainer
                    token={token}
                    authenticated={authenticated}
                    userEmail={userEmail}
                  />
                )}
              />
            )}
          />

          {/* Strategic Services Reports routes */}
          <Route
            path="/client/:client/Strategy/Email"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={<SSREmailLanding />}
              />
            )}
          />
          <Route
            path="/client/:client/Strategy/Email/deliverability_dashboard"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={SSR_DELIVERABILITY_DASH}
                    token={token}
                    heading="Email"
                    subheading="Deliverability Dashboard"
                  />
                )}
              />
            )}
          />
          <Route
            path="/client/:client/Strategy/Ads"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={<SSRAdsLanding />}
              />
            )}
          />
          <Route
            path="/client/:client/Strategy/Ads/champy_ads_results"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={SSR_CHAMPY_ADS}
                    token={token}
                    heading="Ads"
                    subheading="Champy Ads Results"
                  />
                )}
              />
            )}
          />
          <Route
            path="/client/:client/Strategy/Ads/run_ads_results"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <RunAdsReport />
                )}
              />
            )}
          />
          <Route
            path="/client/:client/Strategy/Multi-Channel"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={<SSRMultiChannelLanding />}
              />
            )}
          />

          <Route
            path="/client/:client/Strategy/Multi-Channel/sum_by_source"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={SUM_BY_SOURCE_PARAMS}
                    token={token}
                    heading="Multi-Channel"
                    subheading="Sum by Source"
                  />
                )}
              />
            )}
          />

          {/* Benchmarking and Strategy routes */}
          <Route
            path="/client/:client/Co-Op/Strategy"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={<TDCStrategyLanding />}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Strategy/client_revenue"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_STRAT_CLIENT_REV}
                    token={token}
                    heading="Strategy"
                    subheading="Client Revenue"
                  />
                )}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Strategy/digital_coop_dashboard"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_STRAT_COOP_DASH}
                    token={token}
                    heading="Strategy"
                    subheading="Digital Co-Op Dashboard"
                  />
                )}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Strategy/fixed_cost_landscape"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_STRAT_FIXED_COST_LANDSCAPE}
                    token={token}
                    heading="Strategy"
                    subheading="Fixed Cost Landscape"
                  />
                )}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Strategy/political_marketing_trends"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_STRAT_MARKETING_TRENDS}
                    token={token}
                    heading="Strategy"
                    subheading="Political Marketing/TDC Trends"
                  />
                )}
              />
            )}
          />

          {/* Acquisitions routes */}
          <Route
            path="/client/:client/Co-Op/Acquisition"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={<TDCAcquisitionsLanding />}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Acquisition/preferred_inventory"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_ACQ_INVENTORY}
                    token={token}
                    heading="Acquisition"
                    subheading="Preferred Inventory"
                  />
                )}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Acquisition/delivery_qa_dashboard"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_ACQ_DELIV_CHECKS}
                    token={token}
                    heading="Acquisition"
                    subheading="Delivery QA Dashboard"
                  />
                )}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Acquisition/acquisition_delivery_metrics"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_ACQ_DELIV_METRICS}
                    token={token}
                    heading="Acquisition"
                    subheading="Delivery Metrics"
                  />
                )}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Acquisition/acquisition_performance_analysis"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_ACQ_DELIV_PERFORMANCE_ANALYSIS}
                    token={token}
                    heading="Acquisition"
                    subheading="Internal Performance Analysis"
                  />
                )}
              />
            )}
          />

          <Route
            path="/client/:client/Co-Op/Acquisition/aggregate_results"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Insights
                    heading="Acquisition"
                    pageTitle="Acquistions Results"
                    queries={['searchInsightsAcqDeliveries', 'searchInsightsAcqDeliveriesCohorts']}
                  />
                )}
              />
            )}
          />

          {/* Reactivation routes */}
          <Route
            path="/client/:client/Co-Op/Reactivation"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={<TDCReactivationLanding />}
              />
            )}
          />
          <Route
            path="/client/:client/Co-Op/Reactivation/reactivation_inventory"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_REAC_INVENTORY}
                    token={token}
                    heading="Reactivation"
                    subheading="Inventory"
                  />
                )}
              />
            )}
          />
          <Route
            path="/client/:client/Co-Op/Reactivation/reactivation_performance"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ViewReports
                    tabInfo={COOP_REAC_PERFORMANCE}
                    token={token}
                    heading="Reactivation"
                    subheading="Performance"
                  />
                )}
              />
            )}
          />
          <Route
            path="/client/:client/Co-Op/Reactivation/aggregate_results"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Insights
                    heading="Reactivation"
                    pageTitle="Reactivation Results"
                    queries={['searchInsightsReacDeliveries', 'searchInsightsReacDeliveriesCohorts']}
                  />
                )}
              />
            )}
          />

          {['/client/:client/tdcproducts', '/tdcproducts'].map((path) => (
            <Route
              key="tdcproducts" // doesn't unmount components
              path={path}
              element={(
                <ProtectedRoute
                  component={Menu}
                  body={(
                    <TdcProducts />
                            )}
                />
                        )}
            />
          ))}

          <Route
            path="/settings"
            exact
            element={
              <ProtectedRoute component={Menu} body={<SettingsPage />} />
            }
          />
          <Route
            path="/newclient"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ClientInfo
                    isNew
                    hasTDCAdminPermission={hasTDCAdminPermission}
                    hasDataEngineerPermission={hasDataEngineerPermission}
                    userEmail={userEmail}
                  />
                )}
              />
            )}
          />
          <Route
            path="/advanced"
            exact
            element={
              <ProtectedRoute component={Menu} body={<AdvancedSearch />} />
            }
          />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/logout" exact element={<Logout />} />
          <Route path="/401" exact element={<Unauthorized />} />
        </Routes>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
