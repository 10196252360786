import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';

import FallbackOnError from '../components/FallbackOnError';
import logger from './logger';
import { logReactErrBoundaryError } from '.';

function Login() {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect();

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
      // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>Logging in...</div>
    </ErrorBoundary>
  );
}

export default Login;
