import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import getConfig from './config';

const config = getConfig();

function Auth0ProviderWithHistory({ children }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    const { returnTo = false } = appState;
    navigate(returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/callback`,
        audience: config.audience,
        scope: 'openid email profile read:current_user update:current_user_metadata offline_access',
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      app_env={config.REACT_APP_ENVIRON}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.element,
};
