import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';

import FallbackOnError from '../components/FallbackOnError';
import logger from '../utils/logger';
import { logReactErrBoundaryError } from '../utils';

/**
 * Callback page to determine user permissions and redirect to the appropriate view.
 * @returns redirect behavior based on user authorization
 */
export default function CallbackPage() {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate('/401');
    }
    return navigate('/');
  });

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="page-layout">
        <div className="page-layout__content" />
      </div>
    </ErrorBoundary>
  );
}
