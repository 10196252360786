// acquisition views
const COOP_ACQ_INVENTORY = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DynamicTransformerInventory1010/ProviderandState'],
  labels: ['Preferred Inventory'],
  filterable: [true],
};

const COOP_ACQ_DELIV_CHECKS = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DeliveryChecksDashboard/DeliveryChecksOverviewDashboard?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DeliveryChecksDashboard/GeoExclusions?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DeliveryChecksDashboard/DomainExclusions?:iid=1',
  ],
  labels: ['Delivery QA Dashboard', 'Geo Exclusions', 'Domain Exclusions'],
  filterable: [false, false, false],
};

const COOP_ACQ_DELIV_METRICS = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AAIAcquisition-PredeliveryDonorMetrics2_0/DonorMetrics?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AAIAcquisition-PredeliveryDonorMetrics2_0/EmailEvents?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AAIAcquisition-PredeliveryDonorMetrics2_0/MetricsSummary?:iid=2',
  ],
  labels: ['Donor Metrics', 'Email Events', 'Metrics Summary'],
  filterable: [true, true, true],
};

const COOP_ACQ_DELIV_PERFORMANCE_ANALYSIS = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AdAIPerformanceAnalysis/ROIAnalysis?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AdAIPerformanceAnalysis/BreakevenDate?:iid=1',
  ],
  labels: ['ROI Analysis', 'Breakeven Date'],
  filterable: [false, false],
};

// reactivation views
const COOP_REAC_INVENTORY = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ReactivationInventory_16575591554810/ReactivationInventory?:iid=1',
  ],
  labels: ['Reactivation Inventory'],
  filterable: [true],
};

const COOP_REAC_PERFORMANCE = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTReactivation2022ElectionResultsDashboard/AAIReactivationResults?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTReactivation2022ElectionResultsDashboard/AAIReactivationRevenueInvestment?:iid=1',
  ],
  labels: ['TDC Reactivation Results', 'TDC Reactivation Revenue/Investment'],
  filterable: [true, true],
};

// strategy views
const COOP_STRAT_CLIENT_REV = {
  urls: ['https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenue/ClientRevenue?:iid=1'],
  labels: ['Client Revenue'],
  filterable: [true],
};

const COOP_STRAT_COOP_DASH = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DigitalCo-OpDashboard_16856368093720/GeneralMetrics?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DigitalCo-OpDashboard_16856368093720/AdvantageAIAcquisitionMetrics?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DigitalCo-OpDashboard_16856368093720/AdvantageAIReactivationMetrics?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DigitalCo-OpDashboard_16856368093720/AdvantageAISMSMetrics?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DigitalCo-OpDashboard_16856368093720/AdvantageAIAveragePricePerEmail?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DigitalCo-OpDashboard_16856368093720/AdvantageAIAveragePricePerSMS?:iid=1',
  ],
  labels: ['General Metrics', 'TDC Acquisition Metrics', 'TDC Reactivation Metrics', 'TDC SMS Metrics', 'TDC Avg Price per Email', 'TDC Avg Price per SMS'],
  filterable: [false, true, true, true, true, true],
};

const COOP_STRAT_FIXED_COST_LANDSCAPE = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/FixedCostLandscapeDashboard/AcquisitionSizes?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/FixedCostLandscapeDashboard/BuyTrends?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/FixedCostLandscapeDashboard/vpnovertime?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/FixedCostLandscapeDashboard/PathtoBreakevenbySource?:iid=1',
  ],
  labels: ['Acquisition Sizes', 'Buy Trends', 'VPN over time', 'Path to Breakeven by Source'],
  filterable: [true, true, true, true],
};

const COOP_STRAT_MARKETING_TRENDS = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/MarketingTrends?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/AAI-MarketingTrends?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/YearOverYear-ALL?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/YearOverYear-ALLbyWeek?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/YearOverYear-AdAIOnly?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/DonationsandRevenuePerWeek?:iid=1',
  ],
  labels: ['Marketing Trends', 'TDC Marketing Trends', 'Year Over Year - ALL', 'Year Over Year - ALL by Week', 'Year Over Year - TDC Only', 'Donations and Revenue per Week'],
  filterable: [true, true, false, false, false, true],
};

// strategic services reports

// email
const SSR_DELIVERABILITY_DASH = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DeliverabilityDashboardFMI/FMIOpenRatebyDomain?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DeliverabilityDashboardFMI/FMIOpenRate?:iid=2',
  ],
  labels: ['FMI Open rate by domain', 'FMI Open rate'],
  filterable: [true, true],
};

// ads
const SSR_CHAMPY_ADS = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ChampyAdsResults_16714860218040/KPIsbyCampaignandAudience?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ChampyAdsResults_16714860218040/KPIsbyTime?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ChampyAdsResults_16714860218040/FullReportbyCampaignAudience?:iid=2',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ChampyAdsResults_16714860218040/FullReportbyTime?:iid=3',
  ],
  labels: ['KPI\'s by Campaign and Audience', 'KPI\'s by Time', 'Full Report by Campaign & Audience', 'Full Report by Time'],
  filterable: [true, true, true, true],
};

// multi-channel
const SUM_BY_SOURCE_PARAMS = {
  urls: [
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/MissionPortalSumbySource/SumBySourceChannels?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/MissionPortalSumbySource/SumBySourceCodes?:iid=1',
    'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/MissionPortalSumbySource/Bundling?:iid=1',
  ],
  labels: ['Sum by Source (Channels)', 'Sum by Source (Codes)', 'Bundling'],
  filterable: [true, true, true],
};

export {
  COOP_ACQ_INVENTORY,
  COOP_ACQ_DELIV_CHECKS,
  COOP_ACQ_DELIV_METRICS,
  COOP_ACQ_DELIV_PERFORMANCE_ANALYSIS,
  COOP_REAC_INVENTORY,
  COOP_REAC_PERFORMANCE,
  COOP_STRAT_CLIENT_REV,
  COOP_STRAT_COOP_DASH,
  COOP_STRAT_FIXED_COST_LANDSCAPE,
  COOP_STRAT_MARKETING_TRENDS,
  SSR_DELIVERABILITY_DASH,
  SUM_BY_SOURCE_PARAMS,
  SSR_CHAMPY_ADS,
};
